.Login {
  background: url("/images/dark-material-bg.jpg") 0 0 / cover no-repeat;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Login-form {
  margin: 0 auto;
}

.ne-moze {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
