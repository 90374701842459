.field-label {
  padding-left: 16px;
  padding-top: 0.375em;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 1.5rem;
  text-align: left;
  /* display: flex;
  align-items: center; */
}

.subtermin-create-termin {
  display: grid;
  grid-template-columns: 50% 50%;
}
.subtermin-create-buttons {
  text-align: right;
  margin: 10px 0px;
}

.subtermin-create-buttons :first-child {
  margin-right: 5px;
}

.label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.form-label-right {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  width: 175px;
  text-align: end;
}

.form-label-left {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  width: 175px;
}

@media screen and (max-width: 959px) {
  .form-label-right {
    width: 100%;
    text-align: start;
  }
}

.label-margin-block-start-14 {
  margin-block-start: 14px;
}

.label-margin-block-start-8 {
  margin-block-start: 8px;
}

.label-margin-block-start-5 {
  margin-block-start: 5px;
}

.price-structure {
  margin: 0 80px 0 0;
  display: grid;
  grid-row-gap: 15px;
}

.form {
  padding: 1rem;
  overflow-y: scroll;
  height: calc(100vh - 190px);
}

.form-inquiry {
  padding: 1rem;
  overflow-y: auto;
  max-height: calc(100vh - 85px);
}

.cols-3-modified {
  display: grid;
  grid-template-columns: 300px 100px 30px;
  grid-column-gap: 15px;
}

.form-section-one {
  display: grid;
  grid-row-gap: 34px;
}
/* .form-section-two {
  display: flex;
  justify-content: flex-end;
} */

.form-unit-single {
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-column-gap: 10px;
}

.form-unit-double {
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-gap: 10px;
  margin: 15px 0px;
}

.template-input {
  margin-bottom: 10px;
}

.form-unit-triple {
  display: grid;
  grid-template-columns: 160px 1fr 1fr;
  grid-gap: 10px;
}

.form-unit-triple-icon {
  display: grid;
  grid-template-columns: 160px 1fr 30px;
  grid-column-gap: 10px;
  align-items: center;
}

.form-unit-four-icon {
  display: grid;
  grid-template-columns: 160px 1fr 1fr 20px;
  grid-column-gap: 10px;
}

.form-unit-tripple-two-icons {
  display: grid;
  grid-template-columns: 160px 9fr 1fr 40px;
  grid-column-gap: 10px;
  justify-items: center;
  align-items: center;
}

.second-wrapper {
  display: grid;
  padding: 34px 0;
  grid-row-gap: 34px;
}

.cols-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 34px 20px;
}

.subtermin-table {
  margin: 5px;
  width: 100% !important;
  display: table;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.subtermin-table-thead {
  color: #ffffff;
  background: #6c7ae0;
  width: 100% !important;
  flex-wrap: wrap;
}

.subtermin-table-thead-cell {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset !important;
  padding: 8px 10px;
}
.subtermin-table-tbody {
  background: #fff;
  flex-wrap: wrap;
}

.subtermin-table-tbody-row {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #666666;
  line-height: 1.2;
  font-weight: unset !important;
  border-bottom: 1px solid #f2f2f2;
}

.subtermin-table-tbody-row-cell {
  padding: 8px 10px;
  text-align: center;
}

.DayPickerInput input {
  appearance: none;
  padding: 0.5em 1em;
  border: 1px solid #ddd;
  font-size: 1em;
  text-align: center;
  font-weight: 400;
}
.DayPickerInput-Overlay {
  z-index: 999 !important;
}

.inquiry-buttons > button {
  margin: 0px 16px;
}
.inquiry-buttons > button:nth-child(2) {
  margin: 0px 0px;
}

.inquiry-year-select {
  width: 80px !important;
}

.Select-menu-outer {
  z-index: 100 !important;
}

.pl-16px {
  padding-left: 16px;
}

.cols-10 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

@media only screen and (max-width: 1200px) {
  .cols-10 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 980px) {
  .cols-10 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 830px) {
  .cols-10 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 630px) {
  .cols-10 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-gap: 10px;
  }
}
