.calendar-container {
  right: auto !important;
}

#alotman_month_picker {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 140px;
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}
.customClientNameTextEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px;
}

.toolbar-filter {
  margin-left: 5px;
  height: 36px;
}

.toolbar-filter .DateInput__small {
  height: 36px;
}

.toolbar-filter .DateInput_input {
  border-bottom: none;
  padding: 9px 0px 9px;
}

.stop-booking {
  background-color: #dd4b39 !important;
  color: #fff !important;
  font-weight: 500 !important;
}
