.paperContainer {
  margin-left: 5px;
  height: 36px;
  width: 90px;
}

.gridContainer {
  padding-top: 7px;
}

.viewItem {
  margin-left: 10px !important;
}

.expandIcon {
  height: 20px !important;
  width: 20px !important;
}

.rdtPicker {
  width: 200px !important;
}
