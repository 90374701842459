#customSDP .DateInput {
  padding: 0px 2px !important;
}
#customSDP .DateInput__small {
  width: 87px;
}
#customSDP .SingleDatePickerInput_clearDate__small {
  padding: 0px;
}
#customSDP .SingleDatePickerInput__showClearDate {
  padding-right: 25px;
}
#customSDP .DateInput_input__small {
  line-height: 16px;
}
