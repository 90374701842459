.booking-select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
  width: 100%;
}

.ReactTable .rt-tbody .rt-td {
  display: flex;
  align-items: center;
}

.ReactTable .row-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.ReactTable .row-center svg {
  cursor: pointer;
}
