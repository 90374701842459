#customDRP .DateInput {
  padding: 0px 2px !important;
}
#customDRP .DateInput__small {
  width: 87px;
}
#customDRP .DateRangePickerInput_clearDates__small {
  padding: 0px;
}
#customDRP .DateRangePickerInput__showClearDates {
  padding-right: 25px;
}
#customDRP .DateInput_input__small {
  line-height: 16px;
}
