.alotman-container {
  width: auto;
  overflow: auto;
}

.alotman-container--alotman-table {
  margin-top: 20px;
  margin-bottom: 50px;
  border: 1px solid black;
}

.alotman-container--alotman-table th {
  font-weight: bold;
}

.alotman-container--alotman-table td,
.alotman-container--alotman-table th {
  border: 1px solid black;
  padding: 4px !important;
  text-align: center !important;
  min-width: 24px;
}

.alotman-container--alotman-table .green {
  background: green;
}

.alotman-container--alotman-table .red {
  background: red;
}

.alotman-container--alotman-table .gray {
  background: rgb(230, 230, 230);
  color: rgb(230, 230, 230);
}

.alotman-container--alotman-table .lightgray {
  background: rgb(230, 230, 230);
}

.cursor-pointer {
  cursor: pointer;
}
.green-cell {
  /*background-color: mediumspringgreen;*/
  background-color: #a9ea9f;
}
