.preview {
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: calc(100% - 105px);
  overflow-y: auto;
}

.preview-voucher {
  position: inherit;
}

.voucher-container {
  height: 100%;
  overflow-y: auto;
}

.preview-voucher-page hr {
  width: 90%;
}

.preview-voucher-page table {
  width: 100%;
}

.preview * {
  margin: 0.2em 0;
}

.preview-root {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.preview-footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0px 10px 10px 10px;
  border-top: 2pt solid black;
}

.preview-footer img {
  height: 40px;
}

.preview-footer-text {
  font-size: 10px !important;
}

.preview-footer-text {
  font-size: 10px !important;
}

.preview-footer-header-text {
  font-size: 11px !important;
}

.preview-no-top-margin {
  margin-top: 0 !important;
}

tfoot {
  display: table-footer-group;
}

.fake-footer {
  height: 100px;
}

/**
 ON PRINT
*/
@media print {
  .preview {
    position: static;
    height: 100%;
  }

  .preview-header-db-data {
    font-size: 11px !important;
  }

  .preview-header {
    font-size: 11px !important;
  }
  .preview-header h4 {
    font-size: 14px !important;
  }

  .preview-content-container * {
    font-size: 12px !important;
  }

  .preview-content-title {
    font-size: 12px !important;
  }

  /* This is the key */
  .preview-content {
    page-break-inside: avoid;
  }

  .preview-voucher-page {
    page-break-inside: avoid;
  }

  .preview-voucher-page hr {
    display: none;
  }

  .voucher-container {
    height: inherit;
    overflow-y: unset;
  }
  div > table > thead > tr > th {
    color: black !important;
  }
}

.preview-header {
  width: calc(100% - 16px);
  margin-left: auto;
  margin-right: auto;
}

.preview-title {
  text-align: center;
  margin: 15px 0;
}

.preview-title * {
  font-size: initial;
}

.preview-header-cols-2 {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
}

.preview-header-db-data {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.preview-client-data * {
  font-weight: 700;
}

.preview-float-left {
  float: left;
}
.preview-float-right {
  float: right;
}

.preview-content {
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-columns: 150px auto;
  gap: 10px;
}

.preview-content-program {
  grid-template-columns: 75px auto;
}

.preview-content-program-italian {
  grid-template-columns: 100px auto;
}

.preview-content-container * {
  font-size: 13px;
}

.preview-content-double {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content auto;
  grid-column-gap: 8px;
}

.preview-content-hotels-double {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
}

.preview-content-title {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

.preview-no-padding {
  padding: 0 !important;
}

.preview-no-margin {
  margin: 0 !important;
}

.preview-content-title-breakpoint {
  margin: 15px 0;
}

.preview-content-div {
  /* border: 1px solid #ced4da;
  padding: 10px 12px;
  border-radius: 4px; */
  padding: 0.2em 0;
}

.preview-content-content {
  border: 1px solid #ced4da;
  padding: 10px 12px;
  border-radius: 4px;
  /* padding: 0.2em 0; */
}

.preview-textarea {
  resize: none;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  overflow: hidden;
  background-color: white;
  color: black;
  white-space: pre-wrap;
}

.preview-textarea-disabled {
  resize: none;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  overflow: hidden;
  background-color: white;
  color: rgba(0, 0, 0, 0.38);
}

.preview-item-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preview-uppercase {
  text-transform: uppercase;
}

.preview-signature-container {
  padding: 25px;
}

.preview-signature-container img {
  width: 300px;
  height: 90px;
}

.preview-signature-container > div {
  width: 300px;
  text-align: center;
}

.preview-signature-container > div:nth-child(2) {
  min-width: 200px;
  text-align: center;
}

.preview-signature-container > div > div {
  height: 100px;
}

.preview-signature-container > div > p:first-child {
  padding-bottom: 40px;
}

.preview-signature-container > div > p:nth-child(2) {
  border-bottom: 1px solid black;
}

.preview-comment-textarea {
  width: calc(100% - 26px);
}
