body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  background: #e5e5eb;
}

.toolbar-background {
  background-color: white !important;
}

.bold-text {
  font-weight: bold;
}

.padding-right-16 {
  padding-right: 16px;
}

.right-container {
  margin-left: 256px;
  height: calc(100vh);
  overflow-x: hidden;
  overflow-y: auto;
}
.right-container-minimized {
  margin-left: 65px;
}

.container-area {
  padding: 15px 15px;
}

.sidebar {
  background-color: #43425d !important;
}

.sidebar-minimized {
  width: 65px !important;
  overflow: inherit !important;
}

.sidebar-top {
  color: white !important;
  font-weight: 500 !important;
  letter-spacing: 3px !important;
  text-align: center !important;
  padding-left: 0px !important;
  background-color: #3c3b53 !important;
}

.sidebar-top-icon {
  fill: white !important;
  background-color: #3c3b53 !important;
}

.sidebar-list-item-hidden {
  display: none !important;
}

.sidebar-list-item {
  color: white !important;
  border-left: 5px solid transparent !important;
  text-transform: capitalize;
  transition: none !important;
}

.sidebar-list-item-active {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-left: 5px solid orange !important;
}

.sidebar-list-item-active-minimized {
  height: 48px !important;
  padding: 0px !important;
}

.sidebar-list-item-active-minimized > div > div {
  padding: 0px !important;
}

.sidebar-list-item div div svg {
  fill: white !important;
}

.sidebar-list-item-active div div svg {
  fill: orange !important;
}

.historylog-top {
  top: 57px !important;
  height: calc(100% - 57px) !important;
}
.tooltip {
  left: 65px !important;
}

div .icon-menu {
  left: 60px !important;
}

.wrapperHello {
  border: 10px;
  box-sizing: border-box;
  display: block;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  font-size: 16px;
  font-weight: inherit;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  line-height: 16px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: none;
}

div .icon-menu-wraper {
  margin: -8px 0px !important; /* the real magic */
  padding: 0 !important; /* removes left and right padding */
  cursor: auto !important;
  cursor: initial !important;
  background-color: #43425d !important;
  color: white !important;
}

.grid-double-icon {
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 15px;
}

.hoverSidebar {
  position: relative;
  height: 48px;
  min-height: 48px;
  margin-top: -48px;
  left: 65px;
  z-index: 1000;
  background-color: #43425d !important;
  color: white !important;
  font-size: 16px;
  font-weight: inherit;
  display: block;
  font-family: Roboto, sans-serif;
  line-height: 48px;
  padding: 0px 16px;
  max-height: 987px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
}

.attachmentItem {
  margin-top: 6px;
  margin-bottom: 6px;
}

@media screen {
  .ReactTableFirstColumnDiv {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.ReactTableTooltipWrapperDiv {
  width: 100%;
  height: 100%;
}

.ReactTable .rt-noData {
  display: none !important;
}

.TooltipMaxWidth {
  max-width: 200px;
  right: 0;
}

.textarea-error {
  border: 1px solid red !important;
}

.text-warning {
  color: #ff9100 !important;
}

.SingleDatePicker-error {
  border: 2px solid #f44336 !important;
  display: inline-block;
}

.flex-left {
  display: flex;
  justify-content: left;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.min-width-80 {
  min-width: 80px;
}
.ver-center {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.textarea-stretch {
  width: calc(100% - 24px);
}
.custom-autocomplete-underline::before {
  z-index: 1;
}
.rt-expander {
  margin: 0px 0px 4px 4px !important;
}
.color-red {
  color: #ff7285 !important;
}
.color-green {
  color: #5ec893 !important;
}
.auditLogLimited {
  max-height: 450px;
  overflow-y: scroll;
}
.bgcolor-contact-header {
  padding: 12px !important;
  background-color: #f5f5f5;
}
.cst-flex-between {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 0px 12px !important;
}
.cst-list-box-shadow {
  box-shadow: 0px 0px 2px #f7f7f7;
}
.cursor-default {
  cursor: default;
}

.is-success {
  background-color: #23d160 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.is-success:hover {
  background-color: #22c65b !important;
  border-color: transparent !important;
  color: #fff !important;
}

.area-to-print {
  border: 2px dashed red;
  width: 775pt;
  height: 538pt;
  position: absolute;
  z-index: 1;
  display: none;
}

.toolbar-transparent {
  padding: 0;
  background-color: transparent;
}

@media screen and (max-width: 1020px) {
  .toolbar-sm {
    display: inline-table !important;
    margin-bottom: 15px;
  }
}

.alotman-reservations-hotel-pick {
  padding: 1rem;
  margin-bottom: 36px;
}

.ReactTable input.checkbox {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
       appearance: checkbox;
}

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}
@keyframes passing-through {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30%,
  70% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
}
.dropzone,
.dropzone * {
  box-sizing: border-box;
}

.dropzone {
  width: 300px;
  min-height: 150px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  background: white;
  padding: 54px 54px;
}
.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone.dz-clickable * {
  cursor: default;
}
.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-style: solid;
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}
.dropzone .dz-preview:hover {
  z-index: 1000;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}
.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-image-preview {
  background: white;
}
.dropzone .dz-preview.dz-image-preview .dz-details {
  transition: opacity 0.2s linear;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}
.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}
.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}
.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}
.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}
.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.dropzone .dz-preview:hover .dz-image img {
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}
.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}
.dropzone .dz-preview .dz-image img {
  display: block;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}
.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}
.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  transition: all 0.2s linear;
}
.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}
.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}
.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  transition: width 300ms ease-in-out;
}
.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}
.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}
.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.form-control-grid {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 13px;
}

.partner-client-form {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 34px; */
}

.establishment-form span .form-control-grid select,
.establishment-chain-form .form-control-grid select,
.establishment-legal-entity-form .form-control-grid select,
.activity-form .form-control-grid select,
.user-form .form-control-grid select,
.service-form .form-control-grid select,
.partner-client-form .form-control-grid select,
.partner-service-type-form .form-control-grid select {
  height: 34px;
}

.activity-translations-table {
  width: 100%;
}

.activity-translations-table > tbody > tr > td {
  vertical-align: unset;
}

.mainAdministration {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-column-gap: 20px;
}

.mainAdministration > div:nth-child(2) {
  min-width: 0;
}

.mainAdministration div table th,
.mainAdministration div table td {
  border-bottom: none !important;
}
div table th:first-of-type {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
div table tbody td {
  padding-right: 0px !important;
}
.align-top {
  vertical-align: top !important;
}
.card-create {
  width: "fit-content";
  background-color: #f9fafc !important;
}
.cardactions-button-padding {
  padding-bottom: 30px !important;
  padding-left: 30px !important;
}
.administration-btn-add-new {
  margin-top: 20px;
}

.rap-popover-content {
  background: white;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
    0 8px 24px rgba(16, 22, 26, 0.2);
}

.pad {
  padding: 10px;
}

.calendar-container {
  right: auto !important;
}

#alotman_month_picker {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 140px;
  border-radius: 2px;
  border: 1px solid #dbdbdb;
}
.customClientNameTextEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px;
}

.toolbar-filter {
  margin-left: 5px;
  height: 36px;
}

.toolbar-filter .DateInput__small {
  height: 36px;
}

.toolbar-filter .DateInput_input {
  border-bottom: none;
  padding: 9px 0px 9px;
}

.stop-booking {
  background-color: #dd4b39 !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.DateRangePicker_picker {
  z-index: 100 !important;
}

.DateInput {
  padding: 0px 11px;
}

.DateInput_input {
  padding: 11px 0px 9px;
  font-size: 1rem;
}

.month-year {
  margin-top: -4px;
  display: flex;
  justify-content: center;
  margin-left: 39px;
  margin-right: 39px;
}

.month-year div:nth-child(2) {
  margin: 0 2px;
}

#customDRP .DateInput {
  padding: 0px 2px !important;
}
#customDRP .DateInput__small {
  width: 87px;
}
#customDRP .DateRangePickerInput_clearDates__small {
  padding: 0px;
}
#customDRP .DateRangePickerInput__showClearDates {
  padding-right: 25px;
}
#customDRP .DateInput_input__small {
  line-height: 16px;
}

.field-label {
  padding-left: 16px;
  padding-top: 0.375em;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  margin-right: 1.5rem;
  text-align: left;
  /* display: flex;
  align-items: center; */
}

.subtermin-create-termin {
  display: grid;
  grid-template-columns: 50% 50%;
}
.subtermin-create-buttons {
  text-align: right;
  margin: 10px 0px;
}

.subtermin-create-buttons :first-child {
  margin-right: 5px;
}

.label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
}

.form-label-right {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  width: 175px;
  text-align: end;
}

.form-label-left {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  width: 175px;
}

@media screen and (max-width: 959px) {
  .form-label-right {
    width: 100%;
    text-align: start;
  }
}

.label-margin-block-start-14 {
  -webkit-margin-before: 14px;
          margin-block-start: 14px;
}

.label-margin-block-start-8 {
  -webkit-margin-before: 8px;
          margin-block-start: 8px;
}

.label-margin-block-start-5 {
  -webkit-margin-before: 5px;
          margin-block-start: 5px;
}

.price-structure {
  margin: 0 80px 0 0;
  display: grid;
  grid-row-gap: 15px;
}

.form {
  padding: 1rem;
  overflow-y: scroll;
  height: calc(100vh - 190px);
}

.form-inquiry {
  padding: 1rem;
  overflow-y: auto;
  max-height: calc(100vh - 85px);
}

.cols-3-modified {
  display: grid;
  grid-template-columns: 300px 100px 30px;
  grid-column-gap: 15px;
}

.form-section-one {
  display: grid;
  grid-row-gap: 34px;
}
/* .form-section-two {
  display: flex;
  justify-content: flex-end;
} */

.form-unit-single {
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-column-gap: 10px;
}

.form-unit-double {
  display: grid;
  grid-template-columns: 160px 1fr;
  grid-gap: 10px;
  margin: 15px 0px;
}

.template-input {
  margin-bottom: 10px;
}

.form-unit-triple {
  display: grid;
  grid-template-columns: 160px 1fr 1fr;
  grid-gap: 10px;
}

.form-unit-triple-icon {
  display: grid;
  grid-template-columns: 160px 1fr 30px;
  grid-column-gap: 10px;
  align-items: center;
}

.form-unit-four-icon {
  display: grid;
  grid-template-columns: 160px 1fr 1fr 20px;
  grid-column-gap: 10px;
}

.form-unit-tripple-two-icons {
  display: grid;
  grid-template-columns: 160px 9fr 1fr 40px;
  grid-column-gap: 10px;
  justify-items: center;
  align-items: center;
}

.second-wrapper {
  display: grid;
  padding: 34px 0;
  grid-row-gap: 34px;
}

.cols-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 34px 20px;
}

.subtermin-table {
  margin: 5px;
  width: 100% !important;
  display: table;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}
.subtermin-table-thead {
  color: #ffffff;
  background: #6c7ae0;
  width: 100% !important;
  flex-wrap: wrap;
}

.subtermin-table-thead-cell {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset !important;
  padding: 8px 10px;
}
.subtermin-table-tbody {
  background: #fff;
  flex-wrap: wrap;
}

.subtermin-table-tbody-row {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  color: #666666;
  line-height: 1.2;
  font-weight: unset !important;
  border-bottom: 1px solid #f2f2f2;
}

.subtermin-table-tbody-row-cell {
  padding: 8px 10px;
  text-align: center;
}

.DayPickerInput input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0.5em 1em;
  border: 1px solid #ddd;
  font-size: 1em;
  text-align: center;
  font-weight: 400;
}
.DayPickerInput-Overlay {
  z-index: 999 !important;
}

.inquiry-buttons > button {
  margin: 0px 16px;
}
.inquiry-buttons > button:nth-child(2) {
  margin: 0px 0px;
}

.inquiry-year-select {
  width: 80px !important;
}

.Select-menu-outer {
  z-index: 100 !important;
}

.pl-16px {
  padding-left: 16px;
}

.cols-10 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

@media only screen and (max-width: 1200px) {
  .cols-10 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 980px) {
  .cols-10 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 830px) {
  .cols-10 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-gap: 10px;
  }
}

@media only screen and (max-width: 630px) {
  .cols-10 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 10px;
    grid-gap: 10px;
  }
}

.establishment-divider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 34px 0px;
}

.establishment-divider > div:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.establishment-divider > div {
  padding: 10px;
}

.alotman-container {
  width: auto;
  overflow: auto;
}

.alotman-container--alotman-table {
  margin-top: 20px;
  margin-bottom: 50px;
  border: 1px solid black;
}

.alotman-container--alotman-table th {
  font-weight: bold;
}

.alotman-container--alotman-table td,
.alotman-container--alotman-table th {
  border: 1px solid black;
  padding: 4px !important;
  text-align: center !important;
  min-width: 24px;
}

.alotman-container--alotman-table .green {
  background: green;
}

.alotman-container--alotman-table .red {
  background: red;
}

.alotman-container--alotman-table .gray {
  background: rgb(230, 230, 230);
  color: rgb(230, 230, 230);
}

.alotman-container--alotman-table .lightgray {
  background: rgb(230, 230, 230);
}

.cursor-pointer {
  cursor: pointer;
}
.green-cell {
  /*background-color: mediumspringgreen;*/
  background-color: #a9ea9f;
}

#customSDP .DateInput {
  padding: 0px 2px !important;
}
#customSDP .DateInput__small {
  width: 87px;
}
#customSDP .SingleDatePickerInput_clearDate__small {
  padding: 0px;
}
#customSDP .SingleDatePickerInput__showClearDate {
  padding-right: 25px;
}
#customSDP .DateInput_input__small {
  line-height: 16px;
}

.booking-select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
  width: 100%;
}

.ReactTable .rt-tbody .rt-td {
  display: flex;
  align-items: center;
}

.ReactTable .row-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.ReactTable .row-center svg {
  cursor: pointer;
}

.paperContainer {
  margin-left: 5px;
  height: 36px;
  width: 90px;
}

.gridContainer {
  padding-top: 7px;
}

.viewItem {
  margin-left: 10px !important;
}

.expandIcon {
  height: 20px !important;
  width: 20px !important;
}

.rdtPicker {
  width: 200px !important;
}

.label {
  margin-bottom: 20px;
}

.labelProgramDays {
  margin-top: 20px;
}

.label {
  margin-bottom: 20px;
}

.cardContactSub {
  margin-top: 16px;
}

.containerContactSub {
  padding: 8px;
}

.labelGuideSelect {
  margin-bottom: 16;
  font-size: 14;
}

.loadingLabel {
  margin-top: 20px;
  margin-bottom: 20px;
}

.hidden {
  display: none;
}

.labelGuideShow {
  margin-top: 20px;
}

.labelAnnouncement {
  margin-top: 20px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50px;
}

.Fix {
  background: #43a047;
}

.Pending {
  background: #fdd835;
}

.Storno {
  background: #e53935;
}

.Login {
  background: url("/images/dark-material-bg.jpg") 0 0 / cover no-repeat;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Login-form {
  margin: 0 auto;
}

.ne-moze {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.preview {
  position: fixed;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: calc(100% - 105px);
  overflow-y: auto;
}

.preview-voucher {
  position: inherit;
}

.voucher-container {
  height: 100%;
  overflow-y: auto;
}

.preview-voucher-page hr {
  width: 90%;
}

.preview-voucher-page table {
  width: 100%;
}

.preview * {
  margin: 0.2em 0;
}

.preview-root {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.preview-footer {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0px 10px 10px 10px;
  border-top: 2pt solid black;
}

.preview-footer img {
  height: 40px;
}

.preview-footer-text {
  font-size: 10px !important;
}

.preview-footer-text {
  font-size: 10px !important;
}

.preview-footer-header-text {
  font-size: 11px !important;
}

.preview-no-top-margin {
  margin-top: 0 !important;
}

tfoot {
  display: table-footer-group;
}

.fake-footer {
  height: 100px;
}

/**
 ON PRINT
*/
@media print {
  .preview {
    position: static;
    height: 100%;
  }

  .preview-header-db-data {
    font-size: 11px !important;
  }

  .preview-header {
    font-size: 11px !important;
  }
  .preview-header h4 {
    font-size: 14px !important;
  }

  .preview-content-container * {
    font-size: 12px !important;
  }

  .preview-content-title {
    font-size: 12px !important;
  }

  /* This is the key */
  .preview-content {
    page-break-inside: avoid;
  }

  .preview-voucher-page {
    page-break-inside: avoid;
  }

  .preview-voucher-page hr {
    display: none;
  }

  .voucher-container {
    height: inherit;
    overflow-y: unset;
  }
  div > table > thead > tr > th {
    color: black !important;
  }
}

.preview-header {
  width: calc(100% - 16px);
  margin-left: auto;
  margin-right: auto;
}

.preview-title {
  text-align: center;
  margin: 15px 0;
}

.preview-title * {
  font-size: medium;
  font-size: initial;
}

.preview-header-cols-2 {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto;
}

.preview-header-db-data {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.preview-client-data * {
  font-weight: 700;
}

.preview-float-left {
  float: left;
}
.preview-float-right {
  float: right;
}

.preview-content {
  display: grid;
  /* grid-auto-flow: column; */
  grid-template-columns: 150px auto;
  grid-gap: 10px;
  gap: 10px;
}

.preview-content-program {
  grid-template-columns: 75px auto;
}

.preview-content-program-italian {
  grid-template-columns: 100px auto;
}

.preview-content-container * {
  font-size: 13px;
}

.preview-content-double {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: -webkit-max-content auto;
  grid-template-columns: max-content auto;
  grid-column-gap: 8px;
}

.preview-content-hotels-double {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
}

.preview-content-title {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

.preview-no-padding {
  padding: 0 !important;
}

.preview-no-margin {
  margin: 0 !important;
}

.preview-content-title-breakpoint {
  margin: 15px 0;
}

.preview-content-div {
  /* border: 1px solid #ced4da;
  padding: 10px 12px;
  border-radius: 4px; */
  padding: 0.2em 0;
}

.preview-content-content {
  border: 1px solid #ced4da;
  padding: 10px 12px;
  border-radius: 4px;
  /* padding: 0.2em 0; */
}

.preview-textarea {
  resize: none;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  overflow: hidden;
  background-color: white;
  color: black;
  white-space: pre-wrap;
}

.preview-textarea-disabled {
  resize: none;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  overflow: hidden;
  background-color: white;
  color: rgba(0, 0, 0, 0.38);
}

.preview-item-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preview-uppercase {
  text-transform: uppercase;
}

.preview-signature-container {
  padding: 25px;
}

.preview-signature-container img {
  width: 300px;
  height: 90px;
}

.preview-signature-container > div {
  width: 300px;
  text-align: center;
}

.preview-signature-container > div:nth-child(2) {
  min-width: 200px;
  text-align: center;
}

.preview-signature-container > div > div {
  height: 100px;
}

.preview-signature-container > div > p:first-child {
  padding-bottom: 40px;
}

.preview-signature-container > div > p:nth-child(2) {
  border-bottom: 1px solid black;
}

.preview-comment-textarea {
  width: calc(100% - 26px);
}

