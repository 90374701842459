.labelAnnouncement {
  margin-top: 20px;
}

.circle {
  width: 8px;
  height: 8px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
}

.Fix {
  background: #43a047;
}

.Pending {
  background: #fdd835;
}

.Storno {
  background: #e53935;
}
