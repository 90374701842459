.DateRangePicker_picker {
  z-index: 100 !important;
}

.DateInput {
  padding: 0px 11px;
}

.DateInput_input {
  padding: 11px 0px 9px;
  font-size: 1rem;
}

.month-year {
  margin-top: -4px;
  display: flex;
  justify-content: center;
  margin-left: 39px;
  margin-right: 39px;
}

.month-year div:nth-child(2) {
  margin: 0 2px;
}
