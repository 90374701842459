.form-control-grid {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 13px;
}

.partner-client-form {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 34px; */
}

.establishment-form span .form-control-grid select,
.establishment-chain-form .form-control-grid select,
.establishment-legal-entity-form .form-control-grid select,
.activity-form .form-control-grid select,
.user-form .form-control-grid select,
.service-form .form-control-grid select,
.partner-client-form .form-control-grid select,
.partner-service-type-form .form-control-grid select {
  height: 34px;
}

.activity-translations-table {
  width: 100%;
}

.activity-translations-table > tbody > tr > td {
  vertical-align: unset;
}

.mainAdministration {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-column-gap: 20px;
}

.mainAdministration > div:nth-child(2) {
  min-width: 0;
}

.mainAdministration div table th,
.mainAdministration div table td {
  border-bottom: none !important;
}
div table th:first-of-type {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
div table tbody td {
  padding-right: 0px !important;
}
.align-top {
  vertical-align: top !important;
}
.card-create {
  width: "fit-content";
  background-color: #f9fafc !important;
}
.cardactions-button-padding {
  padding-bottom: 30px !important;
  padding-left: 30px !important;
}
.administration-btn-add-new {
  margin-top: 20px;
}

.rap-popover-content {
  background: white;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
    0 8px 24px rgba(16, 22, 26, 0.2);
}

.pad {
  padding: 10px;
}
