.establishment-divider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 34px 0px;
}

.establishment-divider > div:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.establishment-divider > div {
  padding: 10px;
}
