body {
  margin: 0;
  padding: 0;
  font-size: 13px;
  background: #e5e5eb;
}

.toolbar-background {
  background-color: white !important;
}

.bold-text {
  font-weight: bold;
}

.padding-right-16 {
  padding-right: 16px;
}

.right-container {
  margin-left: 256px;
  height: calc(100vh);
  overflow-x: hidden;
  overflow-y: auto;
}
.right-container-minimized {
  margin-left: 65px;
}

.container-area {
  padding: 15px 15px;
}

.sidebar {
  background-color: #43425d !important;
}

.sidebar-minimized {
  width: 65px !important;
  overflow: inherit !important;
}

.sidebar-top {
  color: white !important;
  font-weight: 500 !important;
  letter-spacing: 3px !important;
  text-align: center !important;
  padding-left: 0px !important;
  background-color: #3c3b53 !important;
}

.sidebar-top-icon {
  fill: white !important;
  background-color: #3c3b53 !important;
}

.sidebar-list-item-hidden {
  display: none !important;
}

.sidebar-list-item {
  color: white !important;
  border-left: 5px solid transparent !important;
  text-transform: capitalize;
  transition: none !important;
}

.sidebar-list-item-active {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-left: 5px solid orange !important;
}

.sidebar-list-item-active-minimized {
  height: 48px !important;
  padding: 0px !important;
}

.sidebar-list-item-active-minimized > div > div {
  padding: 0px !important;
}

.sidebar-list-item div div svg {
  fill: white !important;
}

.sidebar-list-item-active div div svg {
  fill: orange !important;
}

.historylog-top {
  top: 57px !important;
  height: calc(100% - 57px) !important;
}
.tooltip {
  left: 65px !important;
}

div .icon-menu {
  left: 60px !important;
}

.wrapperHello {
  border: 10px;
  box-sizing: border-box;
  display: block;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  font-size: 16px;
  font-weight: inherit;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
  line-height: 16px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background: none;
}

div .icon-menu-wraper {
  margin: -8px 0px !important; /* the real magic */
  padding: 0 !important; /* removes left and right padding */
  cursor: initial !important;
  background-color: #43425d !important;
  color: white !important;
}

.grid-double-icon {
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 15px;
}

.hoverSidebar {
  position: relative;
  height: 48px;
  min-height: 48px;
  margin-top: -48px;
  left: 65px;
  z-index: 1000;
  background-color: #43425d !important;
  color: white !important;
  font-size: 16px;
  font-weight: inherit;
  display: block;
  font-family: Roboto, sans-serif;
  line-height: 48px;
  padding: 0px 16px;
  max-height: 987px;
  width: fit-content;
  white-space: nowrap;
}

.attachmentItem {
  margin-top: 6px;
  margin-bottom: 6px;
}

@media screen {
  .ReactTableFirstColumnDiv {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.ReactTableTooltipWrapperDiv {
  width: 100%;
  height: 100%;
}

.ReactTable .rt-noData {
  display: none !important;
}

.TooltipMaxWidth {
  max-width: 200px;
  right: 0;
}

.textarea-error {
  border: 1px solid red !important;
}

.text-warning {
  color: #ff9100 !important;
}

.SingleDatePicker-error {
  border: 2px solid #f44336 !important;
  display: inline-block;
}

.flex-left {
  display: flex;
  justify-content: left;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-top-60 {
  margin-top: 60px !important;
}
.min-width-80 {
  min-width: 80px;
}
.ver-center {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.textarea-stretch {
  width: calc(100% - 24px);
}
.custom-autocomplete-underline::before {
  z-index: 1;
}
.rt-expander {
  margin: 0px 0px 4px 4px !important;
}
.color-red {
  color: #ff7285 !important;
}
.color-green {
  color: #5ec893 !important;
}
.auditLogLimited {
  max-height: 450px;
  overflow-y: scroll;
}
.bgcolor-contact-header {
  padding: 12px !important;
  background-color: #f5f5f5;
}
.cst-flex-between {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 0px 12px !important;
}
.cst-list-box-shadow {
  box-shadow: 0px 0px 2px #f7f7f7;
}
.cursor-default {
  cursor: default;
}

.is-success {
  background-color: #23d160 !important;
  border-color: transparent !important;
  color: #fff !important;
}

.is-success:hover {
  background-color: #22c65b !important;
  border-color: transparent !important;
  color: #fff !important;
}

.area-to-print {
  border: 2px dashed red;
  width: 775pt;
  height: 538pt;
  position: absolute;
  z-index: 1;
  display: none;
}

.toolbar-transparent {
  padding: 0;
  background-color: transparent;
}

@media screen and (max-width: 1020px) {
  .toolbar-sm {
    display: inline-table !important;
    margin-bottom: 15px;
  }
}

.alotman-reservations-hotel-pick {
  padding: 1rem;
  margin-bottom: 36px;
}

.ReactTable input.checkbox {
  -webkit-appearance: checkbox;
  appearance: checkbox;
}
